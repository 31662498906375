import { atom, useAtom } from 'jotai'
import Link from '@/components/CustomLink'
import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import AuthWrapper from '../components/AuthWrapper'
import InlineError from '../components/InlineError'
import OAuthAuthenticate from '../components/OAuthAuthenticate'
import SeoMetaDashboard from '../components/SeoMetaDashboard'
import { useUserOrganizations } from '../data/organization'
import { useUser } from '../data/user'
import {
  getRecaptchaToken,
  handleLogin,
  hasInvalidInputsLogin,
  signInDiscord,
  signInGithub,
  signInGoogle,
} from '../lib/authHandler'
import { getOrganizationUrl } from '../lib/subdomain'
import { IAdmin, ICustomer } from '@/interfaces/IUser'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { redirectAdminToOrgById } from '@/lib/redirectAdminToOrg'
/* robi backup commit*/

const loginAtom = atom<{ email: string; password: string; type: 'admin' | 'customer' }>({
  email: '',
  password: '',
  type: 'admin',
})

const defaultErrors = { email: '', password: '', response: '' }
const errorsAtom = atom(defaultErrors)
const Login = () => {
  const [loginData, setLoginData] = useAtom(loginAtom)
  const [errors, setErrors] = useAtom(errorsAtom)
  const { user, userMutate } = useUser(true)
  const { orgResults } = useUserOrganizations()
  const router = useRouter()
  const { redirect, feedbackSSO, return_to } = router.query
  const recaptchaRef = useRef<any>()
  const [path, setPath] = React.useState('')

  const redirectToOrg = (redirectPath = '') => {
    if (orgResults?.results?.length === 1) {
      const result = orgResults.results[0]
      redirectAdminToOrgById(result.id, redirectPath)
    } else {
      router.push('/choose-org?redirect=' + redirectPath)
    }
  }

  const redirectToOurOwnBoard = () => {
    // Implement the logic to redirect to your own board here
    // For example:
    // router.push('/our-own-board')
    const url = new URL(
      `https://${
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
          ? 'auth.fbasedev.com'
          : 'auth.featurebase.app'
      }`
    )
    url.pathname = '/api/v1/auth/redirect-to-our-board'
    if (return_to && typeof return_to === 'string') url.searchParams.set('returnTo', return_to)
    router.push(url.toString())
  }

  useEffect(() => {
    if (redirect) {
      const redirectString = redirect?.toString()
      setPath(redirectString ? '?redirect=' + redirectString : '')
    }
  }, [redirect])

  useEffect(() => {
    if (user) {
      if (feedbackSSO === '1') {
        redirectToOurOwnBoard()
      } else {
        redirectToOrg(redirect?.toString() || '')
      }
    }
  }, [router, user, redirect, feedbackSSO])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setErrors(defaultErrors)
    setLoginData((prev) => ({ ...prev, [name]: value }))
  }

  const handleOAuthLogin = async (authFunc: Function) => {
    authFunc('admin', null, (user: IAdmin | ICustomer) => {
      userMutate(user, false)
    })
  }

  return (
    <AuthWrapper>
      <SeoMetaDashboard page={'Sign in'} />
      <h1 className="auth-title">Sign in to Featurebase</h1>
      <OAuthAuthenticate
        onClickHandler={() => handleOAuthLogin(signInGoogle)}
        serviceName="Google"
        mode="register"
      />
      <div className="mt-3">
        <OAuthAuthenticate
          onClickHandler={() => handleOAuthLogin(signInDiscord)}
          serviceName="Discord"
          mode="register"
        />
      </div>
      <div className="mt-3">
        <OAuthAuthenticate
          onClickHandler={() => handleOAuthLogin(signInGithub)}
          // onClickHandler={() => {
          //   signInGithub('admin', null, (user) => {
          //     window.location.href = '/choose-org' + path
          //   })
          // }}
          serviceName="Github"
          mode="register"
        />
      </div>
      <div className="relative w-full my-8">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-100 text-gray-50 dark:border-border"></div>
        </div>
        <div className="relative flex justify-center text-sm leading-5">
          <span className="px-2 font-medium bg-white text-background-accent dark:text-foreground/60 dark:bg-background">
            Or login with email
          </span>
        </div>
      </div>

      <input
        aria-label="email"
        name="email"
        type="email"
        value={loginData.email}
        onChange={handleChange}
        className={`${
          errors.email != '' && 'outline-red-300 dark:outline-red-400/30'
        } register-input`}
        required
        placeholder="Email"
      />
      <input
        aria-label="password"
        name="password"
        type="password"
        value={loginData.password}
        onChange={handleChange}
        className={`${
          errors.email != '' && 'outline-red-300 dark:outline-red-400/30'
        } mt-4 register-input`}
        required
        placeholder="Password"
      />
      <div className="w-full mt-1 text-left">
        {errors.response !== '' && <InlineError error={errors.response}></InlineError>}
      </div>
      <div className="mt-5 mb-4 text-right">
        <Link legacyBehavior href="/forgot-password">
          <a className="text-sm font-medium transition duration-150 ease-in dark:text-foreground/60 text-background-accent/90 hover:text-opacity-80 ">
            Forgot your password?
          </a>
        </Link>
      </div>
      <div className="w-full">
        <button
          className="register-continue-button"
          onClick={async () => {
            if (hasInvalidInputsLogin(loginData, setErrors)) return
            handleLogin(loginData, await getRecaptchaToken(recaptchaRef), setErrors, (resUser) => {
              userMutate(resUser, false)
            })
          }}
        >
          Login
        </button>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6Le2uucZAAAAABsXNpeE8_pJ400DDC9aKVsJQKJy"
      />
      <div className="mt-8 ">
        <Link legacyBehavior href="/register">
          <a className="text-sm font-medium transition duration-150 ease-in dark:text-foreground/60 text-background-accent/90 hover:text-opacity-80">
            Don't have an account? Click here to register
          </a>
        </Link>
      </div>
    </AuthWrapper>
  )
}

export default Login

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en')),
    },
  }
}
