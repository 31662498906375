import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useUser } from '../data/user'

import { useIntercom } from 'react-use-intercom'
import RegisterMenu from './RegisterMenu'
import Script from 'next/script'
import posthog from 'posthog-js'

const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter()
  const { user } = useUser(true)
  const { boot } = useIntercom()

  // On page load or when changing themes, best to add inline in `head` to avoid FOUC
  useEffect(() => {
    // get subdomain from url
    const subdomain = window.location.href.split('.')[0].split('//')[1]
    if (subdomain !== 'auth') {
      // Redirect to a different subdomain
      window.location.href = `https://auth.featurebase.app/${router.pathname}`
    }
  }, [router.pathname])

  function objectIdToTimestamp(objectId: string) {
    try {
      objectId = objectId.toString()
    } catch (e) {}
    if (!/^[0-9a-z]{24}$/.test(objectId)) {
      throw new TypeError('Invalid objectId, got ' + JSON.stringify(objectId))
    }
    return (
      parseInt(objectId.slice(0, 8), 16) * 1000 +
      Math.floor(parseInt(objectId.slice(-6), 16) / 16777.217)
    ) // convert 0x000000 ~ 0xffffff to 0 ~ 999
  }

  useEffect(() => {
    if (user) {
      boot({
        name: user?.name,
        email: user?.email,
        userId: user?.id,
        createdAt: (objectIdToTimestamp(user?.id) / 1000).toString(),
        userHash: user?.intercomHash,
      })
    }
  }, [user])

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <div className="">
      {/* Google Tag */}
      <Script async src={`https://www.googletagmanager.com/gtag/js?id=GTM-N38MN8X`} />
      <Script
        id="google-tag-manager"
        dangerouslySetInnerHTML={{
          __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'GTM-N38MN8X', {
                    page_path: window.location.pathname
                  });
              `,
        }}
      />
      {/* End Google Tag */}
      <div className="relative flex justify-center min-h-screen dark bg-background isloate">
        <div className="absolute inset-0 h-48 -z-10 bg-gradient-to-b dark:from-secondary/30 to-transparent"></div>

        {/* <svg
            className="absolute inset-0 -z-10 h-full w-full dark:stroke-secondary stroke-gray-100/60 [mask-image:radial-gradient(80%_80%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)"
            />
          </svg>
          <svg
            className="absolute inset-0 -z-10 h-full w-full dark:stroke-secondary stroke-gray-100/60 [mask-image:radial-gradient(80%_80%_at_bottom_left,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)"
            />
          </svg> */}
        <RegisterMenu />

        <div className="relative flex flex-col items-center w-full max-w-md mx-4 mt-32 sm:mx-auto ">
          <div className="relative w-full text-center">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default AuthWrapper
