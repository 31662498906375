import React from 'react'
import { useUser } from '../data/user'
import { useCurrentOrganization } from '../data/organization'
import router, { useRouter } from 'next/router'
import { logOut } from '../../network/lib/user'
import { ChevronLeftIcon, OfficeBuildingIcon, PlusCircleIcon } from '@heroicons/react/solid'
import Logo from './Logo'
import { LoginIcon } from '@heroicons/react/outline'
import { Mixpanel } from '@/lib/mixpanel'

const RegisterMenu = () => {
  const { user } = useUser()
  const { org } = useCurrentOrganization()
  const { back, pathname, push } = useRouter()

  return user ? (
    <div className="absolute bottom-0 flex items-end justify-between w-full p-4 px-5 sm:top-0 sm:items-start">
      <div className="relative z-20 flex flex-col-reverse gap-2 sm:flex-col">
        <div>
          <button
            onClick={() => {
              logOut().then(() => {
                Mixpanel.reset()
                router.reload()
              })
            }}
            className="dashboard-secondary inlin-flex"
          >
            <ChevronLeftIcon className="secondary-svg mr-1.5" /> Sign out{' '}
          </button>
        </div>
        <div className="inline-block">
          <p className="text-xs text-background-accent dark:text-foreground">Logged in as:</p>
          <p className="text-background-accent dark:text-foreground mt-0.5 text-xs font-semibold">
            {user?.email}
          </p>
        </div>
      </div>
      <div className="relative z-20 flex items-center gap-4">
        {/* <PublicTheme /> */}
        {!pathname?.includes('choose-org') && (
          <button onClick={() => push('/choose-org')} className="dashboard-secondary">
            <OfficeBuildingIcon className="mr-1.5" /> My organizations
          </button>
        )}
      </div>
    </div>
  ) : (
    <div className="absolute top-0 flex items-end justify-between w-full p-4 px-5 sm:items-start">
      <button className="relative z-20 inline-block unstyled-button" onClick={() => back()}>
        <Logo />
      </button>
      <div className="relative z-20 flex space-x-4">
        {pathname?.includes('register') ? (
          <button onClick={() => push('/login')} className="items-center dashboard-secondary">
            <LoginIcon className="mr-1.5" />
            Sign in
          </button>
        ) : (
          <button onClick={() => push('/register')} className="dashboard-secondary">
            <PlusCircleIcon className="mr-1.5" />
            Register
          </button>
        )}
      </div>
    </div>
  )
}

export default RegisterMenu
